

<template>
  <div class="containerPC">

    <div class="partBox1">

      <div class="carouselBox">
        <el-carousel trigger="click" arrow="never" autoplay loop>
          <el-carousel-item v-for="item in 5" :key="item">
            <img class="bgImage scaleBigSelector" :src='require(`../assets/images/01.${item}_Mac.jpg`)' />
          </el-carousel-item>
        </el-carousel>
      </div>

<!--      <img class="bgImage scaleBigSelector" src="../assets/images/01.1_Mac.jpg" />-->
<!--      <img class="bgImage scaleBigSelector" src="../assets/images/01.2_Mac.jpg" />-->
<!--      <img class="bgImage scaleBigSelector" src="../assets/images/01.3_Mac.jpg" />-->
<!--      <img class="bgImage scaleBigSelector" src="../assets/images/01.4_Mac.jpg" />-->
<!--      <img class="bgImage scaleBigSelector" src="../assets/images/01.5_Mac.jpg" />-->

      <div class="content">
        <div class="nav">
          <div class="left">
            <a href="#"><img class="logo" src="../assets/images/app_logo_white.svg" /></a>
          </div>
          <div class="right">
            <a href="https://cofo.jp/"><img class="link" src="../assets/images/icon01_white.svg" /></a>
            <a href="https://www.youtube.com/channel/UCcgVegwIAaanL6EmaP7Ag6g"><img class="link" src="../assets/images/icon02_white.svg" /></a>
            <a href="https://www.instagram.com/cofo_japan/"><img class="link" src="../assets/images/icon03_white.svg" /></a>
            <a href="https://www.facebook.com/COFOJapan/"><img class="link" src="../assets/images/icon04_white.svg" /></a>
            <a href="https://twitter.com/COFO_Japan"><img class="link" src="../assets/images/icon05_white.svg" /></a>
          </div>
        </div>
        <div class="info opacityChangeSelector">
          <div class="desc1"><br>昇降デスク、ダイニングテーブル、ローテーブルが１台に集約！</div>
          <div class="desc2">人生のあらゆるカタチに。<b class="desc4">COFO JSF Table</b></div>
          <div class="desc3">
            Makuakeにてまもなくプロジェクト公開！<br/>数量限定・特別価格にて“生涯愛用”デスクを手に入れよう！
          </div>
        </div>
        <div class="form">
          <!-- <div class="title"></div> -->
       
         
            <div  >
              <a href="https://www.makuake.com/project/cofo_jsf/" target="_blank" class="bottoms">
            <p>▼先行予約ページはこちらから▼<br><span>【最大4万円OFF、さらに組み立て手数料無料！】 </span></p>
          </a>
          </div>
       
        </div>
      </div>
    </div>

    <div class="partBox2" id="targetElement">
      <div class="topWrap">
        <div class="item">
          <div class="desc1">応援購入総額</div>
          <div class="desc2"><span class="unit">約</span>3.2<span class="unit">億円</span>
          </div>
        </div>
        <div class="item">
          <div class="desc1">応援購入支援者</div>
          <div class="desc2">
            7570<span class="unit">人</span>
          </div>
        </div>
        <div class="item">
          <div class="desc1">応援購入総額ランディング</div>
          <div class="desc2">
            <span class="number"><b>インテリア</b><br>家具部門</span>
            <span class="value">No.1</span>
          </div>
        </div>
      </div>
      <div class="makuake">
        <img class="logo" src="../assets/images/makuake_icon.svg" />
        <div class="desc">
          おかげさまでCOFOシリーズは、応援購入サービス「Makuake」<br/>
          でサポーター数<span class="light">7570</span>人、応援購入総額<span class="light">3.2</span>億円を達成し、<br/>
          インテリア家具部門<span class="light">No.1</span>を獲得いたしました</div>
      </div>
    </div>

<!--   img group-->
    <div class="partBox3">
      <img class="img imgLeft opacityChangeSelector" src="../assets/images/02.jpg" />
      <div class="desc">
        <div class="desc1">昇降デスクとして</div>
        <div class="desc2">ON/OFFの切り替えを自由に。</div>
        <div class="desc3">スタンディングモードで気分一新、座りモードは一番自分に合う高さで心地良く！</div>
      </div>
    </div>
    <div class="partBox3">
      <div class="desc">
        <div class="desc1">ダイニングテーブルに</div>
        <div class="desc2">絶妙なサイズ感で広々に利用可能。</div>
        <div class="desc3">ふたりのディナーでも、わいわい盛り上がるホームパーティでも！<br>４脚×クワッドモーターでしっかりとした安定感を発揮。</div>
      </div>
      <img class="img imgRight opacityChangeSelector" src="../assets/images/03.jpg" />
    </div>
    <div class="partBox3">
      <img class="img imgLeft opacityChangeSelector" src="../assets/images/04.jpg" />
      <div class="desc">
        <div class="desc1">ローテーブルにも</div>
        <div class="desc2">友達を呼びたくなるソファ空間に！</div>
        <div class="desc3">時にはCOFOと一杯のコーヒーだけで、ゆっくり流れる時間をお楽しみください。</div>
      </div>
    </div>

    <div class="aboutUSBox">
      <img class="bgImage scaleBigSelector" src="../assets/images/05_Mac.jpg" />
      <div class="content translateYSelector">
        <div class="title">About us</div>
        <div class="desc1">
          自分らしいスタイルを持つ人たちに、生涯愛用できる机をお届けしたい。<br/>
          COFO×JSFは品格と機能を兼ね備えた“スタンダード”を追求し、<br/>
          多機能デスク「COFO Desk」シリーズの最新作を開発しました。
        </div>
        <div class="desc2">
          仕事をする時も、食事をする時も、寛ぎたい時も。<br/>
          お部屋に優しく溶け込み、あなたの暮らしと人生を包み込んでくれるデスクです。
        </div>
        <div class="btn"><a href="#">Read  More</a></div>
      </div>

    </div>



    <div class="partBox4">
      <div class="desc1">
       <b> COFO × JOURNAL STANDARD FURNITURE</b><br>
        だから実現できた、心地良さと快適さ。</div>
      <div class="desc2">
        コラボチームが大切にしたのは、天然無垢材の上質感と使用環境になじむ美しさです。<br/>
        様々なシーンを想定し、空間と人に調和するデスクを完成させました。<br/>
      </div>
    </div>



    <div class="partBox5">
      <div class="partItem">
        <img class="img opacityChangeSelector" src="../assets/images/06.jpg" />
        <div class="descWrap">
          <div class="desc1">COFO得意の「見せない収納」<br>がさらにパワーアップ</div>
          <div class="line"></div>
          <div class="desc2">テーブル裏にマグネットでカスタマイズ収納可能。ティッシュ、配線などさまざまな対応アクセサリーが充実。</div>
        </div>
      </div>
      <div class="partItem">
        <img class="img opacityChangeSelector" src="../assets/images/07.jpg" />
        <div class="descWrap">
        <div class="desc1">贅沢な高級天然無垢材</div>
        <div class="line"></div>
        <div class="desc2">厚さ2.5cmの天然無垢材を使用した天板は、温かみのある質感と長年使える頑強さを兼ね備えています。自然味あふれる風合いが、毎日に豊かな彩りを添えます。</div>
      </div>
      </div>
    </div>
    <div class="partBox5">
      <div class="partItem">
        <img class="img opacityChangeSelector" src="../assets/images/08.jpg" />
        <div class="descWrap">
        <div class="desc1">ずっと飽きない、使いやすい</div>
        <div class="line"></div>
        <div class="desc2">天板の縁は作業がしやすいように滑らかな曲線フォルムに設計。また、インテリアを引き立てるように、色合いのバランスにもこだわりました。生涯愛用いただける家具として工夫を凝らしています。</div>
      </div>
      </div>
      <div class="partItem">
        <img class="img opacityChangeSelector" src="../assets/images/09.jpg" />
        <div class="descWrap">
        <div class="desc1">充電・給電に便利なポート搭載</div>
        <div class="line"></div>
        <div class="desc2">すぐ手が届く位置にUSB-C/Bポートを設置し、乱雑になりがちなデスク周りをすっきり片付けることができます。スマホやタブレットなどの様々なデバイスをさっと充電・給電可能です。</div>
      </div>
      </div>
    </div>

    <div class="partBoxBottom">
      <div class="info">
        <div class="title">
          Makuakeにてまもなくプロジェクト公開！<br/>
          数量限定・特別価格にて“生涯愛用”デスクを手に入れよう！<br/>
        </div>
      </div>
      <!-- <div class="form">
        <div class="title">メールアドレス</div>
        <div class="inputWrap">
          <el-input placeholder="メルマガ登録で最大25％OFFの限定情報をGET！" v-model="inputVal_2" ref="inputRef_2">
            <template slot="append"><el-button type="primary" @click="sendMail">送信</el-button></template>
          </el-input>
        </div>
        <div class="lineBox">
          <div class="part"></div>
          <div class="center">or</div>
          <div class="part"></div>
        </div>
        <a href="https://lin.ee/BcYZbiA" target="_blank">
          <div class="buttonWrap">
          <el-input type="button" value="LINEアカウントを登録">
            <template slot="append">
              <img class="icon cursor-pointer" src="../assets/images/line_icon.svg" />
            </template>
          </el-input>
        </div>
      </a>
      </div> -->
      <div class="form">
          <!-- <div class="title"></div> -->
       
         
            <div  >
              <a href="https://www.makuake.com/project/cofo_jsf/" target="_blank" class="bottoms">
            <p>▼先行予約ページはこちらから▼<br><span>【最大4万円OFF、さらに組み立て手数料無料！】 </span></p>
          </a>
          </div>
       
        </div>
      <div class="nav">
        <div class="left">
          <a href="#"><img class="logo" src="../assets/images/app_logo_black.svg" /></a>
        </div>
        <div class="right">
          <a href="https://cofo.jp/"><img class="link" src="../assets/images/icon01_black.svg" /></a>
          <a href="https://www.youtube.com/channel/UCcgVegwIAaanL6EmaP7Ag6g"><img class="link" src="../assets/images/icon02_black.svg" /></a>
          <a href="https://www.instagram.com/cofo_japan/"><img class="link" src="../assets/images/icon03_black.svg" /></a>
          <a href="https://www.facebook.com/COFOJapan/"><img class="link" src="../assets/images/icon04_black.svg" /></a>
          <a href="https://twitter.com/COFO_Japan"><img class="link" src="../assets/images/icon05_black.svg" /></a>
        </div>
      </div>

      <div class="desc2">
        2016年創業、本社千葉県。癒しを求め続け、チームの中に20年以上のエンジニアを筆頭に長年リラクゼーション製品<br/>
        の企画・販売に注力して参りました。今の時代に寄り添った、リラックス × 集中カを高める最高のデスクをより多く<br/>
        の方にお届けすベく、今日も奮聞しております。
      </div>
      <div class="desc3">
        <div class="part">会社名：FOS株式会社</div>
        <div class="part">所在地：〒289-1225 千葉県山武市冲渡712-2</div>
      </div>
    </div>
    <div ref="mask" class="overlay-mask" v-if="showMask">
      <p class="mask-message">{{message}}</p>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
export default {
  name: 'Home',
  data(){
    return {
      inputVal: '',
      inputVal_2: '',
      showMask:false,
      message:'',
      animateCtrlArr: [
        {
          selectorName: '.scaleBigSelector',
          animateClass: 'scaleBigClass',
        },
        {
          selectorName: '.translateYSelector',
          animateClass: 'translateYClass',
        },
        {
          selectorName: '.opacityChangeSelector',
          animateClass: 'opacityChangeClass',
        },
      ]
    }
  },
  mounted(){
    this.animateCtrlArr.forEach(item => this.observerDOMFun(item.selectorName, item.animateClass));
  },
  methods:{
    sendMail:debounce(async function(){
        const email = this.inputVal
        if(email && !this.isValidEmail(email)){
          this.showMask = true;
          this.$refs.inputRef.$el.querySelector('input').style.borderColor = 'red';
          console.log(this.$refs.inputRef.$el.querySelector('input'))
          this.message = "メールアドレスを確認してください";
          setTimeout(() => {
            this.showMask = false; // 3秒后隐藏遮罩层
            this.message = "";
            this.$refs.inputRef.$el.querySelector('input').style.borderColor = '';
          }, 3000);
          return;
        }
        const email_2 = this.inputVal_2
        if(email_2 && !this.isValidEmail(email_2)){
          this.showMask = true;
          this.$refs.inputRef_2.$el.querySelector('input').style.borderColor = 'red';
          this.message = "メールアドレスを確認してください";
          setTimeout(() => {
            this.showMask = false; // 3秒后隐藏遮罩层
            this.message = "";
            this.$refs.inputRef_2.$el.querySelector('input').style.borderColor = '';
          }, 3000);
          return;
        }
        // 创建一个CancelToken源
        const source = this.$axios.CancelToken.source();
        try {
          // 构建请求配置
          const config = {
            method: 'post',
            // url:"/api/shopify",
            url: '/shopify/subscribs',
            // headers: {
            //   // 把shop作为请求头参数
            //   'shop': 'cofo',
            // },
            data: {
              email: email || email_2,
              shop: 'cofo',
            },
            cancelToken: source.token, // 注入cancelToken到请求配置中
          };
          // 发送请求
          const response = await this.$axios(config);
          this.showMask = true
          if(response.data.code == 1){
            this.inputVal = ''
            this.inputVal_2 = ''
          }
          this.message = response.data.msg
          setTimeout(() => {
            this.showMask = false; // 3秒后隐藏遮罩层
          }, 3000);
          // console.log(response)
        } catch (error) {
          if (this.$axios.isCancel(error)) {
            // console.log('请求被取消:', error.message);
          } else {
            // console.error('请求失败:', error);
          }
        }
    },500),// 设置防抖延时为500毫秒
    isValidEmail(email) {
      // RFC 5322规定的较为宽松的邮箱格式正则表达式
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(String(email).toLowerCase());
    },
    goToUrl(){
      window.open("https://bit.ly/4cf4YT5", '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.overlay-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.mask-message {
  color: #fff;
  font-size: 16px;
  text-align: center;
}
@function toRem($val){
  @return ($val/119)+rem;
}
$rem:750rem;
.containerPC {
  font-size: 0;
  background-color: #f8f8f8;
  .partBox1{
    padding-bottom: toRem(76);
    position: relative;

    .carouselBox{
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      /deep/ .el-carousel__container {
        height: toRem(700);
      }
      //可调整底部控制条样式
      /deep/ .el-carousel__indicators {
        bottom: toRem(26);
        display: none;
      }
    }

    .bgImage{
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      opacity: 0.1;
    }
    .content{
      position: relative;
      z-index: 10;
    }
    .nav{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: toRem(26) toRem(150) 0 toRem(150);
      .left {
        .logo {
          width: toRem(326);
        }
      }
      .right{
        display: flex;
        .link{
          width: toRem(30);
          height: toRem(30);
          margin-left: toRem(32);
        }
      }
    }
    .info{
      color: #f1eaea;
      text-align: center;
      .desc1{
        b{font-size: toRem(30);}
        font-size: toRem(20);
        margin-top: toRem(80);
        line-height: toRem(40);
        font-weight: 300;
      }
      .desc2{
        font-size: toRem(50);
        margin-top: toRem(30);
        font-weight: bold;
      }
      .desc3{
        font-size: toRem(18);
        margin-top: toRem(56);
        line-height: toRem(26);
      }
      .desc4{font-size: toRem(20); display: block;}
    }
    .form{
      padding: toRem(18) toRem(380) 0;
      .title{
        font-size: toRem(8);
        color: #F3F3F3;
        padding-bottom:  toRem(3);
      }
      .bottoms{
        span{ font-size: toRem(9);
}
        font-size: toRem(13);
          color: #fff;
          margin-top: toRem(20);
          border-radius: 10px;
          background-color: transparent;
          padding: 20px;
          font-family: "Noto Sans JP", sans-serif;
          text-align: center;
          display: block;
          background-color: #2aac2a;
          box-shadow: 0 0 15px #5c5c5c;

         
      }
      .inputWrap{
        /deep/ .el-input__inner{
          font-size: toRem(13);
          color: #fff;
          height: toRem(36);
          background-color: transparent;
          padding: 0 toRem(40);
          font-family: "Noto Sans JP", sans-serif;
        }
        /deep/ .is-invalid .el-input__inner {
          border-color: red;
        }
        /deep/ .el-input-group__append{
          font-size: toRem(16);
          color: #fff;
          background-color: transparent;
          padding: 0 toRem(10);
        }
      }
      .lineBox{
        margin: toRem(14) 0 0;
        display: flex;
        .part{
          flex: 1;
          height: toRem(1);
          background-color: #f5f5f5;
        }
        .center{
          font-size: toRem(14);
          color: #f5f5f5;
          padding: 0 toRem(20);
          position: relative;
          top: toRem(-10);
          line-height: toRem(14);
        }
      }
      .buttonWrap{
        background-color: #2aac2a;
        border-radius: toRem(3);
        /deep/ .el-input__inner{
          height: toRem(36);
          line-height: toRem(36);
          font-size: toRem(14);
          color: #fff;
          background-color: transparent;
          text-align: center;
          border: none;
          outline: none;
          font-family:  "Noto Sans JP", sans-serif;
        }
        /deep/ .el-input-group__append{
          border: none;
          border-left: toRem(1) solid #666;
          background-color: transparent;
          padding: 0 toRem(10);
        }
        .icon{
          width: toRem(22);
          height: toRem(22);
        }
      }
    }
  }

  .partBox2{
    padding: 0 toRem(126);
    .topWrap{
      padding: toRem(68) 0 toRem(54) 0;
      display: flex;
      .item{
        flex: 1;
        border-right: toRem(1) solid #999;
        text-align: center;
        &:last-child{
          border: none;
        }
        .desc1{
          font-size: toRem(16);
          color: #999;
          padding-top: toRem(38);
        }
        .desc2{
          font-size: toRem(60);
          color: #000;
          font-weight: bold;
          padding-top: toRem(25);
          .unit{
            font-size: toRem(18);
          }
          .number{
            font-size: toRem(18);
            display: inline-block;
            text-align: left;
            line-height:toRem(25);
            margin-right:toRem(8);
            b{letter-spacing:toRem(-4);}
          }
          .value{
            font-size: toRem(52);
          }
        }
      }
    }
    .makuake{
      padding: toRem(40) 0;
      border-top: toRem(1) solid #999;
      border-bottom: toRem(1) solid #999;
      display: flex;
      align-items: center;
      margin-bottom: toRem(52);
      .logo{
        width: toRem(270);
        display: block;
        margin: 0 auto;
      }
      .desc{
        font-size: toRem(16);
        color: #999;
        line-height: toRem(32);
        text-align: justify;
        .light{
          font-size: toRem(18);
          color: #666;
          font-weight: bold;
        }
      }
    }
  }

  .partBox3{
    padding-top: toRem(62);
    display: flex;
    .img{
      width: toRem(536);
      opacity: 0.1;
    }
    .imgLeft{
      margin-left: toRem(44);
    }
    .imgRight{
      margin-right: toRem(44);
    }
    .desc{
      flex: 1;
      margin: 0 toRem(100);
      .desc1{
        font-size: toRem(35);
        color: #000;
        padding-top: toRem(66);
        font-weight: bold;
      }
      .desc2{
        font-size: toRem(20);
        color: #000;
        font-weight: 300;
        padding-top: toRem(15);
        padding-bottom: toRem(34);
        line-height: toRem(25);
      }
      .desc3{
        border-top: toRem(1) solid #999;
        font-size: toRem(18);
        color: #666;
        line-height: toRem(30);
        padding-top: toRem(34);
        text-align: justify;
      }
      .desc4{font-size: toRem(20);display: block;}
    }
  }

  .aboutUSBox{
    margin-top: toRem(78);
    text-align: center;
    overflow: hidden;
    position: relative;

    .bgImage{
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      opacity: 0;
    }
    .content{
      position: relative;
      z-index: 1;
    }
    .title{
      font-size: toRem(24);
      color: #fff;
      padding-top: toRem(146);
    }
    .desc1{
      font-size: toRem(21);
      color: #fff;
      line-height: toRem(46);
      padding-top: toRem(40);
    }
    .desc2{
      font-size: toRem(16);
      color: #f1f1f1;
      line-height: toRem(32);
      padding-top: toRem(56);
    }
    .btn{
      width: toRem(156);
      height: toRem(44);
      opacity:0;
      background-color: #fff;
      border-radius: toRem(40);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-top: toRem(56);
      margin-bottom: toRem(140);
      a{
        font-size: toRem(20);
        color: #000;
      }
    }
  }

  .partBox4{
    padding: 0 toRem(80);
    .desc1{
      b{font-size: toRem(22);}
      font-size: toRem(26);
      color: #000;
      padding-top: toRem(70);
      font-weight: bold;
    }
    .desc2{
      font-size: toRem(16);
      color: #666;
      padding-top: toRem(32);
      line-height: toRem(28);
    }
  }

  .partBox5{
    padding: 0 toRem(80);
    margin-top: toRem(48);
    display: flex;
    .partItem{
      flex: 1;
      &:nth-child(2n){
        padding-left: toRem(24);
      }
      &:nth-child(2n+1){
        padding-right: toRem(24);
      }
      .img{
        width: 100%;
        opacity: 0.1;
      }
      .descWrap{
        padding: 0 toRem(32);
        background-color: #fff;
        .desc1{
          font-size: toRem(20);
          color: #000;
          padding-top: toRem(34);
          font-weight: bold;
        }
        .line{
          width: toRem(42);
          height: toRem(2);
          background-color: #999;
          margin-top: toRem(20);
        }
        .desc2{
          font-size: toRem(14);
          color: #666;
          padding-top: toRem(20);
          padding-bottom: toRem(34);
          line-height: toRem(32);
          text-align: justify;
        }
      }
    }
  }

  .partBoxBottom{
    .info{
      text-align: center;
      .title{
        color: #000;
        font-size: toRem(18);
        margin-top: toRem(108);
        line-height: toRem(35);
      }
    }

    .form{
      padding: toRem(18) toRem(380) 0;
      .title{
        font-size: toRem(8);
        color: #F3F3F3;
        padding-bottom:  toRem(3);
      }
      .bottoms{
        span{ font-size: toRem(9);
}
        font-size: toRem(13);
          color: #fff;
          margin-top: toRem(20);
          border-radius: 10px;
          background-color: transparent;
          padding: 20px;
          font-family: "Noto Sans JP", sans-serif;
          text-align: center;
          display: block;
          background-color: #2aac2a;
          box-shadow: 0 0 15px #eee;

         
      }
      .lineBox{
        margin: toRem(14) 0 0;
        display: flex;
        .part{
          flex: 1;
          height: toRem(1);
          background-color: #999;
        }
        .center{
          font-size: toRem(14);
          color: #999;
          padding: 0 toRem(20);
          position: relative;
          top: toRem(-10);
          line-height: toRem(14);
        }
      }
      .buttonWrap{
        background-color: #2aac2a;
        border-radius: toRem(3);
        /deep/ .el-input__inner{
          height: toRem(36);
          line-height: toRem(36);
          font-size: toRem(14);
          color: #fff;
          background-color: transparent;
          text-align: center;
          border: none;
          outline: none;
          font-family:  "Noto Sans JP", sans-serif
        }
        /deep/ .el-input-group__append{
          border: none;
          border-left: toRem(1) solid #f8f8f8;
          background-color: transparent;
          padding: 0 toRem(10);
        }
        .icon{
          width: toRem(22);
          height: toRem(22);
        }
      }
    }

    .nav{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: toRem(86) toRem(150) 0 toRem(150);
      .left {
        .logo {
          width: toRem(326);
        }
      }
      .right{
        display: flex;
        .link{
          width: toRem(30);
          height: toRem(30);
          margin-left: toRem(32);
        }
      }
    }

    .desc2{
      color: #999;
      font-size: toRem(14);
      margin-top: toRem(50);
      padding: 0 toRem(40);
      text-align: center;
      line-height: toRem(26);
    }
    .desc3{
      color: #666;
      font-size: toRem(14);
      padding: toRem(30) toRem(340) toRem(96);
      text-align: center;
      display: flex;
      justify-content: space-between;
    }
  }
}

</style>
